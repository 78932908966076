import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectChipsComponent } from './select-chips.component';
import { AEMatSelectSearchModule } from 'ae-material';
import { MatSelectModule, MatFormFieldModule, MatChipsModule, MatIconModule } from '@angular/material';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';

@NgModule({
    declarations: [
        SelectChipsComponent,
    ],
    imports: [
        CommonModule,
        AEMatSelectSearchModule,
        MatSelectModule,
        MatFormFieldModule,
        FormsModule,
        ReactiveFormsModule,
        MatChipsModule,
        MatIconModule,
        ScrollingModule
    ],
    exports: [
        SelectChipsComponent,
    ],
    providers: [
    ],
    entryComponents: [
    ]
})
export class SelectChipsModule { }

