/**
 * Copyright (C) 2019 Jack Crymble
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AEPostgRESTBackendService } from 'ae-postgrest-services';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { FavouriteNewsfeedService } from 'src/app/favourite-newsfeed.service';
import { MatSnackBar } from '@angular/material';
import { QueryCard, QueryCardInterface } from 'src/app/models/query-card/query-card.model';
import { Tags, TagsInterface } from 'src/app/models/tags/tags.model';

@Injectable()
export class QueryCardService {

    constructor(
        private httpClient: HttpClient,
        private backendService: AEPostgRESTBackendService,
        private favouriteNewsfeedService: FavouriteNewsfeedService,
        private snackBar: MatSnackBar
    ) { }

    getQueryCards(): Observable<Array<QueryCard>> {
        return this.httpClient.get<Array<QueryCardInterface>>(`${this.backendService.url}/queryCards`).pipe(
            map(resp => resp.map(r => new QueryCard(r))),
            catchError(err => {
                this.backendService.handleError(err);
                return of([]);
            })
        );
    }

    getTags(): Observable<Array<Tags>> {
        return this.httpClient.get<Array<TagsInterface>>(`/assets/fake-tags.json`).pipe(
            catchError(error => {
                this.backendService.handleError(error);
                return of([]);
            })
        );
    }

    updateFavourite(card: QueryCardInterface): Observable<QueryCardInterface> {
        const resp =  this.httpClient.put<QueryCardInterface>(`${this.backendService.url}/queries/${card.id}`, {
            favourite: !card.isfavourite
        }, {
            headers: {
                'Prefer': 'return=representation'
            }
        }).pipe(
            map(r => {
                this.snackBar.open(
                    card.isfavourite ? 'Removed from favourites' : 'Added to favourites',
                    null,
                    { duration: 3000 }
                );
                return new QueryCard(r);
            }),
            catchError(err => {
                const newErr = this.backendService.handleError(err);
                if (!newErr.handled) {
                    this.snackBar.open(newErr.message || 'Failed to update favourite', null, { duration: 3000, panelClass: 'aemat-snackbar-error' });
                }
                return of(card);
            })
        );
        this.favouriteNewsfeedService.updateFavouriteNewsfeeds();
        return resp;
    }
}
