/*
 *  Copyright (C) 2019 Matthew Russell, Reuben Trotter
 */

import { NgModule } from '@angular/core';
import { RouterModule, PreloadAllModules } from '@angular/router';
import { AEMatFullLoginRoutes, AEMatProfileRoutes, AEMatUsersRoutes } from 'ae-material';

import { HomeRoutes } from './home/home.routes';
import { AEPostgRESTAuthorizationGuard } from 'ae-postgrest-services';

@NgModule({
    imports: [
        RouterModule.forRoot(
            [
                ...AEMatFullLoginRoutes,
                ...AEMatProfileRoutes,
                ...AEMatUsersRoutes,
                ...HomeRoutes,
                {
                    path: 'newsfeed-list',
                    loadChildren: './newsfeed-list/newsfeed-list.module#NewsfeedListModule',
                    canLoad: [AEPostgRESTAuthorizationGuard],
                },
                {
                    path: 'newsfeed-dashboard',
                    loadChildren: './newsfeed-dashboard/newsfeed-dashboard.module#NewsfeedDashboardModule',
                    canLoad: [AEPostgRESTAuthorizationGuard],
                },
                {
                    path: 'rasff-dashboard',
                    loadChildren: './rasff-dashboard/rasff-dashboard.module#RasffDashboardModule',
                    canLoad: [AEPostgRESTAuthorizationGuard],
                },
                { path: '**', redirectTo: '/home' },
            ],
            { preloadingStrategy: PreloadAllModules }
        )
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
