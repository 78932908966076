/*
 *  Copyright (C) 2019 Matthew Russell, Reuben Trotter
 */

import { Component, ChangeDetectionStrategy, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material';
import { QueryWizardDialogComponent } from '../common/query-card/dialogs/query-wizard-dialog/query-wizard-dialog.component';
import { Subscription } from 'rxjs';
import { QueryCard } from '../models/query-card/query-card.model';
import { testQueryCardData } from '../models/query-card/query-card.data';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeComponent implements OnInit, OnDestroy {

    queryCards: Array<QueryCard> = [];

    openQueryWizardDialogSubscription: Subscription;

    constructor(
        private activatedRoute: ActivatedRoute,
        private dialog: MatDialog
    ) {}

    ngOnInit(): void {
        // this.queryCards = this.activatedRoute.snapshot.data.queryCards;
        this.queryCards = testQueryCardData.map(d => new QueryCard(d));
    }

    ngOnDestroy(): void {
        if (this.openQueryWizardDialogSubscription) {
            this.openQueryWizardDialogSubscription.unsubscribe();
        }
    }

    openQueryWizard() {
        const dialogRef = this.dialog.open(QueryWizardDialogComponent, {
            data: {},
        });

        this.openQueryWizardDialogSubscription = dialogRef.afterClosed().subscribe(result => {
            console.log(result);
        });
    }
}
