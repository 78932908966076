/**
 * Copyright (C) 2019 Jack Crymble, Reuben Trotter
 */

import { Component, OnInit, Input, ChangeDetectorRef, ChangeDetectionStrategy, AfterViewInit, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import * as d3 from 'd3';
import * as dc from 'dc';
import * as crossfilter from 'crossfilter2';

import { QueryCardService } from './query-card.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { QueryCard } from 'src/app/models/query-card/query-card.model';
import { TagsDialogComponent } from './dialogs/tags/tags-dialog.component';

@Component({
    selector: 'app-query-card',
    templateUrl: './query-card.component.html',
    styleUrls: ['./query-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {'(window:resize)': 'onResize()'},
})
export class QueryCardComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() card: QueryCard;
    @ViewChild('sparkLineChart', { static: false }) sparkLineChart: ElementRef;

    ndx: any;
    dimension: any;
    group: any;
    chart: any;

    constructor(
        private queryCardService: QueryCardService,
        private router: Router,
        private cd: ChangeDetectorRef,
        private dialog: MatDialog,
    ) { }

    ngOnInit() {
        this.ndx = crossfilter(this.card.data);
        this.dimension = this.ndx.dimension(d => new Date(d.date));
        this.group = this.dimension.group().reduceSum(d => d.stories);
    }

    ngAfterViewInit() {
        const keys: any = this.group.all().map(dc.pluck('key')).slice();

        this.chart = dc.lineChart(this.sparkLineChart.nativeElement)
            .dimension(this.dimension)
            .group(this.group)
            .brushOn(false)
            .x(d3.scaleTime().domain([keys[0], keys[keys.length - 1]]))
            .ordinalColors(['#69B845'])
            .margins({top: 16, right: 16, bottom: 16, left: 16})
            .clipPadding(16);
        this.chart.xAxis().ticks(0);
        this.chart.yAxis().ticks(0);

        this.onResize();
    }

    ngOnDestroy() {
        dc.chartRegistry.deregister(this.chart);
    }

    onResize() {
        this.chart
            .width((<HTMLDivElement>this.sparkLineChart.nativeElement).offsetWidth)
            .height((<HTMLDivElement>this.sparkLineChart.nativeElement).offsetHeight);
        this.chart.render();
    }

    onSidenavEvent() {
        this.chart.width((<HTMLDivElement>this.sparkLineChart.nativeElement).offsetWidth);
        this.chart.redraw();
    }


    showNewsfeed() {
        console.log('Show newsfeed');
        this.router.navigateByUrl(`newsfeed/${this.card.id}`);
    }

    showDashboard() {
        console.log('Show dashboard');
        this.router.navigateByUrl(`dashboard/${this.card.id}`);
    }

    showTags(event: Event) {
        const dialogRef = this.dialog.open(TagsDialogComponent, {
            width: '700px',
            data: this.card,
        });
        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
        });
        event.stopPropagation();
    }

    showNotifications(event: Event) {
        // Show modal containing notifications?
        console.log('Show notifications');
        event.stopPropagation();
    }

    updateFavourite(event: Event) {
        console.log('Update favourite');
        this.queryCardService.updateFavourite(this.card).subscribe(resp => {
            this.card = resp;
            this.cd.markForCheck();
        });
        event.stopPropagation();
    }

}
