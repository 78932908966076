/**
 * Copyright (C) 2019 Jack Crymble
 */

import { QueryCardInterface } from './query-card.model';

export const testQueryCardData: Array<QueryCardInterface> = [
    {
        id: 0,
        name: 'All stories',
        isfavourite: false,
        tags: [],
        notifications: [],
        data: [
            {
                date: '2019-11-22',
                stories: 2
            },
            {
                date: '2019-11-23',
                stories: 3
            },
            {
                date: '2019-11-24',
                stories: 1
            },
            {
                date: '2019-11-25',
                stories: 4
            }
        ]
    },
    // {
    //     id: 0,
    //     name: 'Meat',
    //     isfavourite: true,
    //     tags: [],
    //     notifications: [],
    //     data: [
    //         {
    //             date: '2019-11-22',
    //             stories: 2
    //         },
    //         {
    //             date: '2019-11-23',
    //             stories: 3
    //         }
    //     ]
    // }

];
