/*
 *  Copyright (C) 2019 Reuben Trotter
 */
import { Component, ChangeDetectionStrategy, ChangeDetectorRef, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { QueryCardService } from './../../query-card.service';

@Component({
    selector: 'app-tags-dialog',
    templateUrl: './tags-dialog.component.html',
    styleUrls: ['./tags-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagsDialogComponent implements OnInit{

    constructor(
        @Inject(MAT_DIALOG_DATA) public data,
        public dialogRef: MatDialogRef<TagsDialogComponent>,
        private queryCardService: QueryCardService,
        private cd: ChangeDetectorRef,
    ) { }

    currentTags: Array<string>;
    optionsTag: Array<string> = [];
    initialTagOptions: Array<string> = [];
    tagsToAdd: Array<object> = [];
    editDialog = false;

    ngOnInit() {
        // @TODO: Return tags from db
        this.currentTags = ["f", "g", "b", "c", "y", "u", "t",];

        // @TODO: Ensure this function returns correct result from db
        this.queryCardService.getTags().subscribe(result => {
            this.optionsTag = result["tags"];
            this.initialTagOptions = [];
            this.cd.markForCheck();
        })
    }

    remove(tag): void {
        // @TODO: Add dialog option to ensure user wants to do this
        const index = this.currentTags.indexOf(tag);

        if (index >= 0) {
            this.currentTags.splice(index, 1);
        }
    }

    addTag() {
        // @TODO: Clear current chips in both mat chip lists && call change detection to update currentTags
        // @TODO: Ensure chips have been posted to db
        console.log("Added");
    }

    editMode() {
        this.editDialog = !this.editDialog;
        console.log("Set to:", this.editDialog);

    }
}