import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { QueryCardService } from 'src/app/common/query-card/query-card.service';
import { Tags } from '../../../models/tags/tags.model';

@Injectable()
export class TagsResolver implements Resolve<Array<Tags>> {

    constructor(
        private queryCardService: QueryCardService
    ) {}

    resolve(route: ActivatedRouteSnapshot): Observable<Array<Tags>> {
        return this.queryCardService.getTags();
    }
}
