import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InsertChipsComponent } from './insert-chips.component';
import { MatFormFieldModule, MatChipsModule, MatIconModule, MatInputModule } from '@angular/material';
import { FormsModule } from '@angular/forms';

@NgModule({
    declarations: [
        InsertChipsComponent,
    ],
    imports: [
        CommonModule,
        MatFormFieldModule,
        FormsModule,
        MatInputModule,
        MatChipsModule,
        MatIconModule,
    ],
    exports: [
        InsertChipsComponent,
    ],
    providers: [
    ],
    entryComponents: [
    ]
})
export class InsertChipsModule { }

