/*
 *  Copyright (C) 2019 Matthew Russell
 */

import { Routes } from '@angular/router';
import { AEPostgRESTAuthorizationGuard } from 'ae-postgrest-services';

import { HomeComponent } from './home.component';
import { QueryCardResolver } from '../common/query-card/resolvers/query-card.resolver';
import { TagsResolver } from '../common/query-card/resolvers/tags.resolver';


export const HomeRoutes: Routes = [
    {
        path: 'home',
        component: HomeComponent,
        resolve: {
            queryCards: QueryCardResolver,
            tagsRes: TagsResolver
        },
        canActivate: [AEPostgRESTAuthorizationGuard],
    },
];
