import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AEPostgRESTBackendService } from 'ae-postgrest-services';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export interface NewsfeedInterface {
    id: number;
    name: string;
}

@Injectable()
export class FavouriteNewsfeedService {

    private favourites = new Subject<Array<NewsfeedInterface>>();

    constructor(
        private httpClient: HttpClient,
        private backendService: AEPostgRESTBackendService
    ) { }

    updateFavouriteNewsfeeds() {
        this.getFavouriteNewsfeedsBackend().subscribe(resp => {
            this.favourites.next(resp);
        });
    }

    getFavouriteNewsfeeds(): Observable<Array<NewsfeedInterface>> {
        return this.favourites.asObservable();
    }

    private getFavouriteNewsfeedsBackend(): Observable<Array<NewsfeedInterface>> {
        return this.httpClient.get<Array<NewsfeedInterface>>(`${this.backendService.url}/queries?isfavourite=is.true`).pipe(
            catchError(err => {
                this.backendService.handleError(err);
                return of([]);
            })
        );
    }



}
