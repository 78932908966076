import { Component, OnInit, Input, ViewChild, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { MatSelect } from '@angular/material';

@Component({
    selector: 'app-select-chips',
    templateUrl: './select-chips.component.html',
    styleUrls: ['./select-chips.component.scss'],
})

export class SelectChipsComponent implements OnInit, AfterViewInit {
    @ViewChild('matSelect', {static: false}) matSelect: MatSelect;
    @Input() options: Array<string>;
    @Input() initialValue: Array<string> = [];
    @Output() chipSelected: EventEmitter<{confirm: boolean, chips: Array<string>}> = new EventEmitter<{confirm: boolean, chips: Array<string>}>();

    filteredOptions: Array<string>;
    selected: Array<string> = null;
    selection: Array<string> = [];

    ngOnInit() {
        this.filteredOptions = Array.from(this.options);
        this.selected = this.initialValue;
        this.selection = this.initialValue;
        this.chipSelected.emit({confirm: true, chips: this.selection});
    }

    ngAfterViewInit() {
        this.matSelect.valueChange.subscribe(value => {
            this.selection.push(value);
            this.chipSelected.emit({confirm: true, chips: this.selection});
        });
    }

    filterOptions(text: string) {
        if (text) {
            this.filteredOptions = Array.from(this.options).filter(o => o.toLowerCase().includes(text.toLowerCase()));
        } else {
            this.filteredOptions = Array.from(this.options);
        }
    }

    remove(item: string): void {
        const index = this.selection.indexOf(item);

        if (index >= 0) {
            this.selection.splice(index, 1);
        }

        if (this.selection.length === 0) {
            this.chipSelected.emit({confirm: true, chips: this.selection});
        }
    }
}
