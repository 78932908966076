/**
 * Copyright (C) 2019 Jack Crymble
 */

import { NgModule } from '@angular/core';
import { MatIconModule, MatButtonModule, MatTooltipModule, MatDialogModule, MatCardModule, MatInputModule, MatSelectModule, MatChipsModule } from '@angular/material';
import { MatExpansionModule } from '@angular/material/expansion';
import { FormsModule } from '@angular/forms';
import { QueryCardResolver } from './resolvers/query-card.resolver';
import { TagsResolver } from './resolvers/tags.resolver';
import { QueryCardComponent } from './query-card.component';
import { TagsDialogComponent } from './dialogs/tags/tags-dialog.component';
import { AEDashboardDCLineChartContainerModule } from 'ae-dashboard-dc';
import { SelectChipsModule } from 'src/app/common/mat-chips/select-chips/select-chips.module';
import { InsertChipsModule } from 'src/app/common/mat-chips/insert-chips/insert-chips.module';
import { QueryCardService } from './query-card.service';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
        MatTooltipModule,
        MatDialogModule,
        MatInputModule,
        MatExpansionModule,
        MatCardModule,
        FormsModule,
        MatSelectModule,
        MatChipsModule,
        SelectChipsModule,
        InsertChipsModule,
        AEDashboardDCLineChartContainerModule,
    ],
    exports: [
        QueryCardComponent
    ],
    entryComponents: [
        TagsDialogComponent,
    ],
    declarations: [
        QueryCardComponent,
        TagsDialogComponent,
    ],
    providers: [
        QueryCardResolver,
        TagsResolver,
        QueryCardService,
    ],
})
export class QueryCardModule { }
