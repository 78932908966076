import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QueryWizardDialogComponent } from './query-wizard-dialog.component';
import { MatDialogModule, MatButtonModule } from '@angular/material';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';


@NgModule({
    imports: [
        CommonModule,
        MatDialogModule,
        MatButtonModule,
        MatInputModule,
        MatSelectModule,
    ],
    exports: [
        QueryWizardDialogComponent
    ],
    declarations: [
        QueryWizardDialogComponent
    ],
    providers: [],
})
export class QueryWizardDialogModule { }
