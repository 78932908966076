import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
    selector: 'app-insert-chips',
    templateUrl: './insert-chips.component.html',
    styleUrls: ['./insert-chips.component.scss'],
})

export class InsertChipsComponent implements OnInit {
    @Input() options: Array<string>;
    @Input() initialValue: Array<string> = [];
    @Output() chipSelected: EventEmitter<{ confirm: boolean, chips: Array<string> }> = new EventEmitter<{ confirm: boolean, chips: Array<string> }>();

    visible = true;
    removable = true;
    addOnBlur = true;

    readonly separatorKeysCodes: number[] = [ENTER, COMMA];
    inputArr = []

    ngOnInit() {
        this.chipSelected.emit({ confirm: true, chips: this.inputArr });
    }

    add(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;

        if ((value || '').trim()) {
            this.inputArr.push(value.trim());
        }

        if (input) {
            input.value = '';
        }
    }

    remove(item: string): void {
        const index = this.inputArr.indexOf(item);

        if (index >= 0) {
            this.inputArr.splice(index, 1);
        }
    }
}
