/*
 *  Copyright (C) 2019 Matthew Russell, Reuben Trotter
 */

import { Component, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { AEPostgRESTBackendService } from 'ae-postgrest-services';
import { AEMatAppContainerMenuItem } from 'ae-material';
import { FavouriteNewsfeedService } from './favourite-newsfeed.service';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
    appTitle = 'horizon-scanner';

    favouriteNewsfeeds: Array<any> = [];

    barItems: {[key: string]: AEMatAppContainerMenuItem} = {
        "Home": {
            title: "Home",
            icon: "home",
            link: "/home",
            roles: []
        },
        "Newsfeed List": {
            title: "Newsfeed List",
            icon: "view_list",
            link: "/newsfeed-list",
            roles: []
        },
        "Newsfeed Dashboard": {
            title: "Newsfeed Dashboard",
            icon: "dashboard",
            link: "/newsfeed-dashboard",
            roles: []
        },
        "RASFF Dashboard": {
            title: "RASFF Dashboard",
            icon: "language",
            link: "/rasff-dashboard",
            roles: []
        },
    };

    get barKeys(): Array<string> {
        if (this.backendService.isLoggedIn) {
            return Object.keys(this.barItems);
        } else {
            return [];
        }
    }

    get menus(): Array<AEMatAppContainerMenuItem> {
        if (this.backendService.isLoggedIn) {
            return [
                this.barItems['Home'],
                {},
                this.barItems['Newsfeed List'],
                this.barItems['Newsfeed Dashboard'],
                {},
                ...this.favouriteNewsfeeds,
                {title: "Manage Users", icon: "group", link: "/users", roles: ['Admin']},
                {title: "Profile", icon: "person", link: "/profile/user", roles: []},
                {title: "Log Out", icon: "lock_outline", link: "/logout"},
            ];
        } else {
            return [
                {title: "Log In", icon: "lock_outline", link: "/login"},
            ];
        }
    }

    profilemenu: Array<any> = [
        {title: "Theme Settings", icon: "style", link: "/profile/theme", roles: []},
        {title: "User Settings", icon: "person", link: "/profile/user", roles: []},
        {title: "Log Out", icon: "lock_outline", link: "/logout", roles: []},
    ];

    loginSubscription: Subscription;
    favouriteNewsfeedSubsciption: Subscription;

    constructor(
        private cd: ChangeDetectorRef,
        private titleService: Title,
        public backendService: AEPostgRESTBackendService,
        private favouriteNewsfeedsService: FavouriteNewsfeedService
    ) {}

    ngOnInit() {
        this.titleService.setTitle(this.appTitle);

        this.loginSubscription = this.backendService.loginEvent.subscribe(event => {
            this.cd.markForCheck();
        });

        this.favouriteNewsfeedSubsciption = this.favouriteNewsfeedsService.getFavouriteNewsfeeds().subscribe(resp => {
            this.favouriteNewsfeeds = resp.map(d => {
                return {
                    title: `${d.name} Newsfeed`,
                    icon: 'view_list',
                    link: `/newsfeed/${d.id}`
                };
            });

            if (this.favouriteNewsfeeds.length > 0) { // If has favourite newsfeeds then add title
                this.favouriteNewsfeeds.splice(this.favouriteNewsfeeds.length, 0, {}); // Spacer line for end of favourites
            }
            this.cd.markForCheck();
        });
    }

    ngOnDestroy() {
        this.loginSubscription.unsubscribe();
        this.favouriteNewsfeedSubsciption.unsubscribe();
    }
}
