import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { QueryCardService } from 'src/app/common/query-card/query-card.service';
import { QueryCard } from 'src/app/models/query-card/query-card.model';

@Injectable()
export class QueryCardResolver implements Resolve<Array<QueryCard>> {

    constructor(
        private queryCardService: QueryCardService
    ) {}

    resolve(route: ActivatedRouteSnapshot): Observable<Array<QueryCard>> {
        return this.queryCardService.getQueryCards();
    }
}
