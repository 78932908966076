/*
 *  Copyright (C) 2019 Matthew Russell, Reuben Trotter
 */

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { AmplifyAngularModule, AmplifyService, AmplifyModules } from 'aws-amplify-angular';
import Auth from '@aws-amplify/auth';

import { MarkdownModule } from 'ngx-markdown';
import { AEMatAppContainerModule, AEMatLoginModule, AEMatProfileModule, AEMatUsersModule, AEMatUsersResolver, AEMatRolesResolver, AEMatUKDateAdapterModule, AEMatPrivacyPolicyServiceModule, AEMatSidenavEventServiceModule } from 'ae-material';
import { AEPostgRESTSearchServiceModule, AEPostgRESTProfileServiceModule, AEPostgRESTAuthorizationModule, AEPostgRESTAuthorizationInterceptorModule, AEPostgRESTFilterServiceModule, AEPostgRESTUserAdminAuthorizationModule } from 'ae-postgrest-services';
import { AECognitoBackendServiceModule, AECognitoUserServiceModule } from 'ae-cognito-services';
import { AEDashboardModule, AEDashboardSynchronisationServiceModule, } from 'ae-dashboard';
import { NgxFlagIconCssModule } from 'ngx-flag-icon-css'

import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeModule } from './home/home.module';
import { CommonModule } from '@angular/common';
import { FavouriteNewsfeedService } from './favourite-newsfeed.service';
import { MatSnackBarModule } from '@angular/material';
@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        MarkdownModule.forRoot(),

        AmplifyAngularModule,
        AEPostgRESTSearchServiceModule,
        AEPostgRESTFilterServiceModule,
        AEPostgRESTProfileServiceModule.forRoot({
            themes: [
                {name: "Light", value: "light-theme"},
                {name: "Dark", value: "dark-theme"},
                {name: "Green", value: "green-theme"},
            ],
        }),
        AEPostgRESTAuthorizationModule,
        AEPostgRESTAuthorizationInterceptorModule,
        AEPostgRESTUserAdminAuthorizationModule,
        AEMatAppContainerModule,
        AEMatLoginModule,
        AEMatProfileModule,
        AEMatUsersModule,
        AEMatUKDateAdapterModule,
        AEMatSidenavEventServiceModule,
        AEMatPrivacyPolicyServiceModule,
        AECognitoBackendServiceModule.forRoot({
            url: environment.backendUrl,
        }),
        AECognitoUserServiceModule,

        AEDashboardModule,
        AEDashboardSynchronisationServiceModule,
        HomeModule,
        MatSnackBarModule,
        AppRoutingModule,
        NgxFlagIconCssModule
    ],
    providers: [
        {
            provide: AmplifyService,
            useFactory:  () => {
                return AmplifyModules({
                    Auth,
                });
            }
        },
        AEMatUsersResolver,
        AEMatRolesResolver,
        FavouriteNewsfeedService
    ],
    declarations: [
        AppComponent,
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule { }
