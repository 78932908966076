/**
 * Copyright (C) 2019 Jack Crymble
 */

import { NotificationInterface } from '../notifications/notifications.model';

interface QueryCardDataInterface {
    date: string;
    stories: number;
}

export interface QueryCardInterface {
    id: number;
    name: string;
    isfavourite: boolean;
    tags: Array<string>;
    notifications: Array<NotificationInterface>;
    data: Array<QueryCardDataInterface>;
}

export class QueryCard {
    id: number;
    name: string;
    isfavourite: boolean;
    tags: Array<string>;
    notifications: Array<NotificationInterface>;
    data: Array<QueryCardDataInterface>;

    constructor(data: QueryCardInterface) {
        this.id = data.id;
        this.name = data.name;
        this.isfavourite = data.isfavourite;
        this.tags = data.tags;
        this.notifications = data.notifications;
        this.data = data.data;
    }
}
