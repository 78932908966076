/*
 *  Copyright (C) 2019 Matthew Russell, Reuben Trotter
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeComponent } from './home.component';
import { QueryCardModule } from '../common/query-card/query-card.module';
import { MatIconModule, MatButtonModule, MatDialogModule } from '@angular/material';
import { QueryWizardDialogModule } from '../common/query-card/dialogs/query-wizard-dialog/query-wizard-dialog.module';
import { QueryWizardDialogComponent } from '../common/query-card/dialogs/query-wizard-dialog/query-wizard-dialog.component';

@NgModule({
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
        MatDialogModule,
        QueryCardModule,
        QueryWizardDialogModule
    ],
    declarations: [
        HomeComponent,
    ],
    exports: [
        HomeComponent,
    ],
    entryComponents: [
        QueryWizardDialogComponent
    ]
})
export class HomeModule {}
