import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-query-wizard-dialog',
  templateUrl: './query-wizard-dialog.component.html',
  styleUrls: ['./query-wizard-dialog.component.scss']
})
export class QueryWizardDialogComponent implements OnInit {

  constructor(
      public dialogRef: MatDialogRef<QueryWizardDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {}

  create(): void {

  }

}
